$(function () {
    $('.menuToggleTop').on('click', function () {
        $('.menuTop').slideToggle(300, function () {



            if ($(this).css('display') === 'none'){
                $(this).removeAttr('style');
            }

        })
    })
})


$(function () {
    $('.filterToggle').on('click', function () {
        $('.filterBlock').slideToggle(300, function () {



            if ($(this).css('display') === 'none'){
                $(this).removeAttr('style');
            }

        })
    })
})