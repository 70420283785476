$(document).ready(function(){
    //Получаем куку в переменную
    var check = $.cookie('check');

    if(check == 'hide'){
        $(".advertisement").addClass("advertisement--close").removeClass("advertisement--open");
        $(".advertisement__arrow").addClass("advertisement__arrow--close").removeClass("advertisement__arrow--open");
    }


    $(".advertisement__arrow").on("click", function (e) {

        if($(this).hasClass("advertisement__arrow--open")){
            $(".advertisement").addClass("advertisement--close").removeClass("advertisement--open");
            $(".advertisement__arrow").addClass("advertisement__arrow--close").removeClass("advertisement__arrow--open");

            $.cookie('check', 'hide', {
                expires: 1,
                path: '/'
            });


        }else{
            $(".advertisement").removeClass("advertisement--close").addClass("advertisement--open");
            $(".advertisement__arrow").removeClass("advertisement__arrow--close").addClass("advertisement__arrow--open");
            $.cookie('check', null);
        }


    })


});